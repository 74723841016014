import { inject, Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { translate } from '@app/core/translate/translate';

@Injectable()
export class MessageService {
  private messageService = inject(NzMessageService);

  success(message: string) {
    return this.messageService.success(translate(message));
  }

  info(message: string) {
    return this.messageService.info(translate(message));
  }

  error(message: string) {
    return this.messageService.error(translate(message));
  }
}
